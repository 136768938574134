html {
    --neutral-invert: #FFFFFF;
    --neutral-lightest: #F4F4F4;
    --neutral-lighter: #E3E1E6;
    --neutral-light: #CCCACE;
    --neutral-medium: #A4A3A7;
    --neutral-dark: #585560;
    --neutral-darker: #22202C;
    --neutral-darkest: #12101D;
    --neutral-full: #000000;
    --green: #20bf6b;
    --border-radius: 1.2rem;
  }
  
  html {
    box-sizing: border-box;
    font-size: 62.5%;
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  html, body {
    width: 100%;
    height: 100%;
  }
  
  body {
    background: var(--neutral-lighter);
    color: var(--neutral-medium);
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 700px) {
    body {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }
  
  button {
    cursor: pointer;
    color: inherit;
    font-family: inherit;
  }
  button:focus, button:active {
    outline: 0;
  }
  
  .c-neu-button {
    border: 0;
    padding: 0;
    margin: 0;
    height: 6.4rem;
    width: 6.4rem;
    border-radius: var(--border-radius);
    background: linear-gradient(135deg, var(--neutral-lighter), rgba(255, 255, 255, 0.24));
    position: relative;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.14), 2px 2px 4px -1px rgba(0, 0, 0, 0.12), 5px 5px 12px -3px rgba(0, 0, 0, 0.1), 12px 12px 25px -5px rgba(0, 0, 0, 0.08);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--neutral-lighter);
    transition: all 200ms ease-out 0s;
  }
  .c-neu-button:before, .c-neu-button:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: var(--border-radius);
    background: transparent;
    transition: all 200ms ease-out 0s;
  }
  .c-neu-button:before {
    box-shadow: -1px -1px 2px 0px rgba(255, 255, 255, 0.54), -2px -2px 4px -1px rgba(255, 255, 255, 0.38), -5px -5px 12px -3px rgba(255, 255, 255, 0.38), -12px -12px 25px -5px rgba(255, 255, 255, 0.24);
  }
  .c-neu-button:after {
    box-shadow: inset 0px 0px 0.8rem rgba(255, 255, 255, 0.38);
  }
  .c-neu-button__icon {
    color: inherit;
    width: 3.2rem;
    height: 3.2rem;
    -webkit-animation: buttonIconInactive 400ms ease-out 50ms forwards;
            animation: buttonIconInactive 400ms ease-out 50ms forwards;
    transition: all 200ms ease-out 0s;
  }
  .c-neu-button:active {
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.24), inset 0px 0px 4px rgba(0, 0, 0, 0.14), inset 0px 0px 4.8rem rgba(0, 0, 0, 0.08);
    border-color: var(--neutral-light);
  }
  .c-neu-button:active:before {
    box-shadow: inset 0px 0px 4px rgba(255, 255, 255, 0.14);
  }
  .c-neu-button:active:after {
    box-shadow: inset 0px 0px 0.8rem rgba(0, 0, 0, 0.04);
  }
  .c-neu-button--active {
    box-shadow: inset 0px 0px 1.6rem rgba(0, 0, 0, 0.24);
    background: var(--green);
    border-color: rgba(0, 0, 0, 0.15);
    color: var(--neutral-invert);
  }
  .c-neu-button--active:before {
    box-shadow: inset 0px 0.8rem 0.8rem rgba(255, 255, 255, 0.24);
  }
  .c-neu-button--active:after {
    box-shadow: inset 0px 0 0.4rem rgba(0, 0, 0, 0.14);
  }
  .c-neu-button--active .c-neu-button__icon {
    -webkit-animation: buttonIconActive 400ms ease-out 50ms forwards;
            animation: buttonIconActive 400ms ease-out 50ms forwards;
  }
  .c-neu-button--rounded {
    --border-radius: 50%;
  }
  
  .c-neu-button {
    margin: 8rem;
    transform: scale(1.5);
  }
  @media screen and (max-width: 700px) {
    .c-neu-button {
      margin-bottom: 2.4rem;
    }
  }
  
  @-webkit-keyframes buttonIconInactive {
    0% {
      opacity: 0;
      transform: translateX(-2.4rem);
    }
    25% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes buttonIconInactive {
    0% {
      opacity: 0;
      transform: translateX(-2.4rem);
    }
    25% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @-webkit-keyframes buttonIconActive {
    0% {
      opacity: 0;
      transform: translateX(2.4rem);
    }
    25% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @keyframes buttonIconActive {
    0% {
      opacity: 0;
      transform: translateX(2.4rem);
    }
    25% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }